<template>
  <div class="confirmed-faces">
    <p class="has-text-weight-bold is-marginless">PAST PARTICIPANTS</p>
    <p class="is-size-7">
      Join our growing list of faces to display your own branding statement to our readers!
    </p>
    <p
      class="has-text-weight-light"
      v-for="company in confirmed"
      :key="company"
      v-html="company"
    />
  </div>
</template>

<script>
export default {
  props: {
    confirmed: {
      type: Array,
      required: true,
    },
  },
};
</script>
